import io from "socket.io-client";
import TokenService from 'app/services/tokenService';

// Connect socket
const accessToken = TokenService.getAccessToken();
export const channelSocket = io('https://api.dev.boom.my/channel-session', {
    path: '/visitor/socket.io', 
    auth: {
        token: accessToken
    }
})

export const exhibitorSocket = io('https://api.dev.boom.my/exhibitor-room', {
    path: '/visitor/socket.io', 
    auth: {
        token: accessToken
    }
})

export const agentSocket = io('https://api.dev.boom.my/agent', {
    path: '/visitor/socket.io', 
    auth: {
        token: accessToken
    }
})