import axios from "axios";

axios.defaults.baseURL = '//api.dev.boom.rocks/visitor'

//PROD API //api.boom.rocks/visitor
//DEV API  //api.dev.boom.rocks/visitor

const ApiAction = {
    get(url, params = {}, headers = {}) {
        return axios.get(url, { params, headers });
    },

    post(url, body, config = {}) {
        return axios.post(url, body, config);
    },

    delete(url, params = {}) {
        return axios.delete(url, { params });
    },

    put(url, body) {
        return axios.put(url, body);
    },

    patch(url) {
        return axios.patch(url);
    }

};

export default ApiAction;
